/*
 * @Description: 结算相关
 * @Author: huangkun
 * @Date: 2020-06-08 11:29:55
 * @Last Modified by: huangkun
 * @Last Modified time: 2024-03-13 15:12:18
 */

// GET请求api
const getApi = {
  shoppingaddressList: '/v1/app/admin/shippingaddress/list', //收货地址列表
  shoppingaddressDefault: '/v1/app/admin/shippingaddress/default', //收货地址列表
  productOrderPage: '/v1/app/admin/order/page/user', //用户分页查询订单
  productOrderInfo: '/v1/app/admin/order/info', //查询订单详情
  shoppingaddressDeInfo: '/v1/app/admin/shippingaddress/info', //根据ID查询收货地址
};

const postApi = {
  addressCreate: '/v1/app/admin/shippingaddress/create', // 收货地址创建
  addressUpdate: '/v1/app/admin/shippingaddress/update', // 收货地址更新
  productOrderCreate: '/v1/app/admin/order/create', //创建订单
  productOrderPay: '/v1/app/admin/order/pay', //支付接口
  productOrderReceived: '/v1/app/admin/order/received', //订单收货
  addressDelete: '/v1/app/admin/shippingaddress/delete', //收货地址删除
};

const exportApi = {};

export default { getApi, postApi, exportApi };
