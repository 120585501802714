/*
 * @Description: 过滤器
 * @Author: huangkun
 * @Date: 2022-04-26 14:27:17
 * @Last Modified by: huangkun
 * @Last Modified time: 2022-04-26 14:34:35
 */

import common from './enums/common';

// 对象合并
const concatObj = {
  ...common,
};

// 对象转MAP
const toMapFun = (obj: object) => {
  const newObj = {};
  for (const [k, v] of Object.entries(obj)) {
    newObj[k] = new Map(Object.entries(v));
  }
  return newObj;
};

const objBox = toMapFun(concatObj);

// 过滤器参数定义
interface filterFun {
  (key: string, filterKey?: string): any;
}
// 第二参数定义
interface filterFunTwo {
  (key: string, needEmpty?: boolean): any;
}

export default function filter() {
  // 用于过滤枚举值 filterKey
  const comFilter: filterFun = (key: string, filterKey?: string) => {
    const result: any = objBox[key];
    if (filterKey) {
      if (result?.get(filterKey)) {
        return result.get(filterKey);
      } else {
        return filterKey;
      }
    } else {
      return filterKey;
    }
  };
  // 是否把map对象转为原始对象 { key:value }
  const originalObjFilter: filterFunTwo = (key: string, needEmpty = false) => {
    const result: any = objBox[key];
    if (needEmpty) {
      result.set('', '-');
    }
    const obj = {};
    for (const [k, v] of result) {
      obj[k] = v;
    }
    return obj;
  };
  // 用于生成 [{ lable: value, value: key }] 数组类型
  const toObjArrFilter: filterFunTwo = (key: string, needEmpty = false) => {
    let result: any = objBox[key];
    if (needEmpty) {
      result.set('', '-');
    }
    const arr = [];
    // eslint-disable-next-line @typescript-eslint/no-shadow
    for (const [key, val] of result.entries()) {
      arr.push({ lable: val, value: key });
    }
    result = arr;
    return result;
  };
  return {
    comFilter,
    originalObjFilter,
    toObjArrFilter,
  };
}
