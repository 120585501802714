import { stringify } from 'qs';

export function getStringify(obj: any, options?: any) {
  if (!obj) {
    return '';
  }
  const defaultOption = {
    // 输出结果中Null值将不会含有等号
    strictNullHandling: true,
    // skipNulls选项可以忽略Null值的解析
    skipNulls: true,
    // 自定义属性，是否需要除去  默认空值
    isEmpty: true,
    // 对象保留小数点
    allowDots: true,
    ...options,
  };
  const { isEmpty, ...others } = defaultOption;
  // 如果需要清除为值为空的属性，重组obj，手动将空值转为null null在序列化的时候会被清除
  if (isEmpty) {
    Object.keys(obj).forEach((item) => {
      if (!obj[item]) {
        // eslint-disable-next-line no-param-reassign
        obj[item] = null;
      }
    });
  }
  return stringify(obj, others);
}

/**
 *  请求数据过滤空值 post
 * @param {object} data 输入的对象
 */
export function filterPostParams(target: any, map = new WeakMap()) {
  if (typeof target === 'object') {
    const cloneTarget: any = Array.isArray(target) ? [] : {};
    if (map.get(target)) {
      return map.get(target);
    }
    map.set(target, cloneTarget);
    // eslint-disable-next-line guard-for-in
    for (const key in target) {
      let canclone = true;
      if (target[key] === 0 || target[key] === '0') {
        canclone = true;
      } else if (!target[key]) {
        canclone = false;
      }
      if (canclone) {
        cloneTarget[key] = filterPostParams(target[key], map);
      }
    }
    return cloneTarget;
  }
  return target;
}

/**
 * 将URL中的路径参数替换为数据对象中对应的值，并返回替换后的URL。
 * 如果数据对象中存在未匹配的路径参数，则会抛出一个错误。
 * @param url 包含路径参数的URL
 * @param data 包含路径参数值的对象
 * @returns 替换后的URL，或者抛出一个错误
 */
export function replaceUrlPathParams(
  url: string,
  data: Record<string, string>,
): string {
  // 检查参数是否为空
  if (!url || !data) {
    throw new Error('参数不能为空');
  }

  // 查找所有路径参数并替换为数据对象中对应的值
  const lostParams: string[] = [];
  const resolvedUrl = url.replace(/\{([^}]+)\}/g, (_: string, arg: string) => {
    if (!data[arg]) {
      lostParams.push(arg);
    }
    return data[arg] || '';
  });

  // 检查是否存在未匹配的路径参数
  if (lostParams.length) {
    throw new Error(`在数据对象中找不到以下路径参数：${lostParams.join(', ')}`);
  }

  return resolvedUrl;
}
