// @ts-ignore
import Cookies from 'js-cookie';
import Catch from './cache';

export function getToken(): string {
  const TokenKey = Cookies.get('appId') || 'mall-Token';

  // return localStorage.getItem(TokenKey) as string;
  return Catch.get(TokenKey);
}

/**
 * 默认设置的Cookies 有效期为一天
 * @param { obj } token  zheshi token
 */
export function setToken(token: string) {
  const TokenKey = Cookies.get('appId') || 'mall-Token';
  // return localStorage.setItem(TokenKey, token);
  return Catch.set(TokenKey, token, 1000);
}

export function removeToken() {
  const TokenKey = Cookies.get('appId') || 'mall-Token';
  // return localStorage.removeItem(TokenKey);
  return Catch.clear(TokenKey);
}

// 退出方法
export async function toLogoOut(url: any = ''): void {
  const token: string = getToken() || '';
  if (token) {
    removeToken();
  }
  const scan = sessionStorage.getItem('scan') || '';
  if (scan) {
    console.log('扫码不跳转');
    return;
  }
  // Cookies.remove('appId');
  console.log('退出');
  if (url) {
    location.href = url;
  } else {
    location.href = '/';
  }
  return Promise.resolve();
}

// 退出方法
export async function toLogoOutNoScan(): void {
  const token: string = getToken() || '';
  if (token) {
    removeToken();
  }
  location.href = '/';
}
