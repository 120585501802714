/*
 * @Description: 认证中心相关接口
 * @Author: huangkun
 * @Date: 2019-10-22 15:48:18
 * @Last Modified by: huangkun
 * @Last Modified time: 2024-03-13 15:11:48
 */

// GET请求api
const getApi = {
  userinfoPage: '/v1/app/auth/userinfo/page?', // 分页查询用户
  userinfoList: '/v1/app/auth/userinfo/list?', // 查询用户
  userinfoFindId: '/v1/app/auth/userinfo/', // 查询用户id
  // 登录相关
  userExtendInfo: '/v1/app/admin/user/extend/by_', // 查询用户拓展信息
  applicationInfo: '/v1/app/authz/application/info', // 获取应用信息
  // 查询手机号
  userinfoCountBy: '/v1/app/authz/userinfo/count_by?', // 获取应用信息
};

// POST请求api
const postApi = {
  login: '/v1/app/authz/login', // 登录
  phoneCodeSend: '/v1/app/auth/user/phone_code/send', // 发送验证码
  wechatJsapiSign: '/v1/app/authz/wechat/jsapi/sign', // 签名

  userinfoLogCreate: '/v1/app/userinfo/h5/log/create', // 创建用户活动日志

  resetPwdByPhone: '/v1/app/authz/user/pwd/reset_by_phone', // 通过手机号（验证码）重置用户密码
  userPhoneBind: '/v1/app/auth/user/phone/bind', // 用户绑定手机号
  userinfoCreate: '/v1/app/authz/register', // 创建用户

  // 暂未使用
  userinfoUpdate: '/v1/app/auth/userinfo/', // 编辑用户
  userPwdReset: '/v1/app/auth/user/pwd/reset', // 重置用户密码
  userPwdUpdate: '/v1/app/auth/user/pwd/', // 更新用户密码
  fileUpload: '/v1/app/file/upload', // 上传文件
};

// delete请求api
const deleteApi = {
  logOut: '/v1/app/authz/token', // 退出
};

// 导出api
const exportApi = {};

export default { getApi, postApi, deleteApi, exportApi };
