/*
 * @Description: 认证中心相关接口
 * @Author: huangkun
 * @Date: 2019-10-22 15:48:18
 * @Last Modified by: xiongqiaolin
 * @Last Modified time: 2024-05-31 17:29:33
 */

// GET请求api
const getApi = {
  itemInfo: '/v1/app/school_charge/item/info?', // 查询收费项目详情
  grade: '/v1/app/school_charge/grade/school_id/{merchantId}/{schoolId}', // 根据需要编号查询年级（管理员-传入商户id）
  classStudentList: '/v1/app/school_charge/student/pay_situation?', // 查询学生
  productList: '/v1/app/admin/product/list?', //查询商品列表
  productPage: '/v1/app/admin/product/page?', //查询商品列表
  productInfo: '/v1/app/admin/product/info', //查询商品id

  // 查询抽奖活动
  activityInfo: '/v1/app/admin/activity/by_', // 根据ID查询
  activityWinnerList: '/v1/app/activity/winner/list?', // 查询用户中奖记录

  activityList: '/v1/app/admin/activity/list', // 查询活动列表
  productCategoryList: '/v1/app/product_category/list', // 产品类目
  productSpecsList: '/v1/app/product_specs/list', // 产品规格列表
  // 首页的轮播广告
  advertiseInfo: '/v1/app/admin/advertise/info?', //广告
  // weChatAuthorize: '/v1/app/admin/order/we_chat/authorize?', // 微信授权申请
  weChatAuthorize: '/v1/app/authz/wechat/redirect_url?', // 微信授权申请
  alipayAuthorize: '/v1/app/authz/ali/redirect_uri?', // 支付宝授权申请
};

// POST请求api·
const postApi = {
  // 下单
  orderCreate: '/v1/app/admin/order/create', // 下单
  weChatLogin: '/v1/app/admin/order/we_chat/login', // 微信登录
  alipayLogin: '/v1/app/admin/order/ali_pay/login', // 支付宝登录
  // 创建用户信息
  adminExtendCreate: '/v1/app/admin/user/extend/create', // 创建用户拓展信息
  // 抽奖
  activityWinnerLottery: '/v1/app/activity/winner/lottery', // 支付宝登录
  activityWinnerLotteryGy: '/v1/app/activity/winner/lottery/gy', // 贵阳
  activityWinnerCheckGY: '/v1/app/activity/winner/lottery/check/gy', // 用户抽奖检查 贵阳
  activityWinnerCheck: '/v1/app/activity/winner/lottery/check', // 用户抽奖检查
  activityWinnerCheckHB: '/v1/app/activity/winner/lottery/check/hb', // 用户抽奖检查 河北
  activityWinnerCheckHBTask: '/v1/app/activity/winner/lottery/check/hbtask', // 用户抽奖检查 河北任务
  //补全用户信息
  completeTheInformation: '/v1/app/userinfo/completeTheInformation',
};

// 导出api
const exportApi = {};

export default { getApi, postApi, exportApi };
