/*
 * @Description: 项目通用枚举
 * @Author: huangkun
 * @Date: 2020-11-24 15:59:39
 * @Last Modified by: huangkun
 * @Last Modified time: 2023-11-02 18:09:39
 */
const statusType = {
  ENABLE: '启用',
  DISABLE: '禁用',
};

const yesOrNoType = {
  YES: '是',
  NO: '否',
};

const productOrderType = {
  PAY_WAIT: '等待支付',
  PAYING: '支付中',
  PAY_FAIL: '支付失败',
  SEND_WAIT: '待发货',
  SEND_SUCCESS: '已发货',
  END: '已完成',
  SHUT_DOWN: '已取消',
};

const channelListType = {
  LU_FU: '路付',
};

const type = {
  PACKS: '礼包',
  VOUCHERS: '兑换码',
  NONE: '未中奖',
  WX_MONEY: '微信红包',
  WX_PAY_MONEY: '微信立减金',
  ALI_MONEY: '支付宝金额',
  ALI_PAY_MONEY: '支付宝立减金',
  ALI_PAY_ACCOUNT_MONEY: '支付宝账户红包',
};
const format = {
  WHEEL: '/lottery_wheel',
  SQUARE: '/lottery_square',
  ANSWER: '/lottery_answer',
};
export default {
  statusType,
  yesOrNoType,
  productOrderType,
  channelListType,
  type,
  format,
};
