/*
 * @Description: 请求汇总
 * @Author: huangkun
 * @Date: 2022-04-07 18:02:31
 * @Last Modified by: huangkun
 * @Last Modified time: 2023-10-31 18:28:58
 */

import auth from './auth';
import api from './api';
import shopping from './shopping';

const getApi = {
  ...auth.getApi,
  ...api.getApi,
  ...shopping.getApi,
};

const postApi = {
  ...auth.postApi,
  ...api.postApi,
  ...shopping.postApi,
};

// 删除api
const deleteApi = {
  ...auth.deleteApi,
};

const exportApi = {};

export { getApi, postApi, exportApi, deleteApi };
