import { isJSON } from '.';

const EXPIRE = 0;

class Cache {
  cacheExpireKey = '_expire_';

  getTimeStamp() {
    return Math.round(new Date() / 1000);
  }

  setExpire(key, expire = EXPIRE) {
    if (expire) {
      localStorage.setItem(
        `${key}${this.cacheExpireKey}`,
        this.getTimeStamp() + expire,
      );
    }
  }

  isExpired(key) {
    try {
      const expireTime = localStorage.getItem(`${key}${this.cacheExpireKey}`);
      if (expireTime) {
        const currentTime = this.getTimeStamp();
        if (currentTime > parseInt(expireTime)) {
          localStorage.removeItem(key);
          localStorage.removeItem(`${key}${this.cacheExpireKey}`);
          return true;
        }
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  set(key, data, expire = EXPIRE) {
    try {
      const jsonData = typeof data === 'object' ? JSON.stringify(data) : data;
      this.setExpire(key, expire);
      localStorage.setItem(key, jsonData);
      return true;
    } catch (error) {
      return false;
    }
  }

  get(key, defaultValue = null, expire = EXPIRE) {
    try {
      if (!this.isExpired(key)) {
        const data = localStorage.getItem(key);
        if (isJSON(data)) {
          return JSON.parse(data);
        }
        return data;
      }
      if (typeof defaultValue === 'function') {
        const value = defaultValue();
        this.set(key, value, expire);
        return value;
      } else {
        this.set(key, defaultValue, expire);
        return defaultValue;
      }
    } catch (error) {
      return defaultValue;
    }
  }

  clear(key) {
    try {
      localStorage.removeItem(`${key}${this.cacheExpireKey}`);
      localStorage.removeItem(key);
      return true;
    } catch (error) {
      return false;
    }
  }

  clearAll() {
    try {
      Object.keys(localStorage).forEach((key) => {
        localStorage.removeItem(key);
      });

      return true;
    } catch (error) {
      return false;
    }
  }
  sessionAll() {
    Object.keys(sessionStorage).forEach((key) => {
      sessionStorage.removeItem(key);
    });
  }
}

export default new Cache();
