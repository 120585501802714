export function getBrowserType() {
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf('MicroMessenger') > -1) {
    return 'wechat';
  } else if (userAgent.indexOf('AlipayClient') > -1) {
    return 'alipay';
  } else {
    return 'other';
  }
}

export function validatePhoneNumber(phoneNumber: string) {
  // 使用正则表达式匹配手机号码
  const regex = /^1[1-9]\d{9}$/;
  return regex.test(phoneNumber);
}

/**
 * 将URL中的路径参数替换为数据对象中对应的值，并返回替换后的URL。
 * 如果数据对象中存在未匹配的路径参数，则会抛出一个错误。
 * @param url 包含路径参数的URL
 * @param data 包含路径参数值的对象
 * @returns 替换后的URL，或者抛出一个错误
 */
export function replaceUrlPathParams(
  url: string,
  data: Record<string, string>,
): string {
  // 检查参数是否为空
  if (!url || !data) {
    throw new Error('参数不能为空');
  }

  // 查找所有路径参数并替换为数据对象中对应的值
  const lostParams: string[] = [];
  const resolvedUrl = url.replace(/\{([^}]+)\}/g, (_: string, arg: string) => {
    if (!data[arg]) {
      lostParams.push(arg);
    }
    return data[arg] || '';
  });

  // 检查是否存在未匹配的路径参数
  if (lostParams.length) {
    throw new Error(`在数据对象中找不到以下路径参数：${lostParams.join(', ')}`);
  }

  return resolvedUrl;
}

/**
 * 判断是否为json对象
 * @param {string} str 传入字符串
 */
export function isJSON(str: string) {
  if (typeof str === 'string') {
    try {
      const obj = JSON.parse(str);
      if (typeof obj === 'object' && obj) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
  return false;
}

export function processString(str: string) {
  if (str.length <= 7) {
    return str; // 如果字符串长度小于等于7，直接返回原始字符串
  } else {
    let firstThree = str.substring(0, 3); // 截取前三位字符
    let lastFour = str.substring(str.length - 4); // 截取后四位字符
    return firstThree + '***' + lastFour; // 拼接字符串并返回
  }
}
// 记录活动跳转url以便登录后跳转到相应页面
export function activityRecords(item: string) {
  localStorage.setItem('activityRecords', item);
}
