/*
 * @Description: 封装umi-request
 * @Author: huangkun
 * @Date: 2020-05-08 10:51:53
 * @Last Modified by: xiongqiaolin
 * @Last Modified time: 2024-08-02 10:28:03
 */
/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { message } from 'antd';
import type {
  RequestMethod,
  RequestOptionsInit,
  RequestResponse,
  ResponseError,
} from 'umi-request';
import { extend } from 'umi-request';
// import { toLogoOut } from '@/utils/auth';
import type { Service } from 'ahooks/lib/useRequest/src/types';
import { getToken, toLogoOut } from '../auth';
import { filterPostParams } from './utils';
const _ = require('lodash');

/**
 *  预处理请求的函数，函数只返回请求的body
 * @param service
 * @returns
 */
export const preTreatmentService = (service: Service<any, any>) => {
  return async (obj?: any) => {
    const result = await service(obj);
    return result?.body;
  };
};

// 拓展请求参数 自定义
/**
 * 增加的参数
 * @param {boolean} filterParams 可选属性 请求拦截过滤为空的参数
 * @param {Array} filterCode 可选属性 响应拦截 过滤不需要提示的code
 */
interface ExRequestOptionsInit extends RequestOptionsInit {
  filterParams?: boolean;
  needAuth?: boolean;
  needToken?: boolean;
  filterCode?: number[];
}

// 重写重载
interface ExRequestMethod<R = false> extends RequestMethod {
  <T = any>(url: string, options?: ExRequestOptionsInit): R extends true
    ? Promise<RequestResponse<T>>
    : Promise<T>;
}

/**
 * 异常处理程序
 */
//  项目配置将请求的异常配置
const errorHandler = _.throttle(
  (error: ResponseError) => {
    const { response } = error;
    let massageText = '未知错误';
    // 请求头
    console.error(error?.response?.headers);
    // 响应的请求
    console.error(error?.request);
    if (response && response.status) {
      // 状态码
      // 响应数据
      console.log(error.data);
      // 可以获取到请求响应的 单独识别401
      // if (response.status === 401) {
      //   message.error('登录过期，请重新登录。' || '未知异常');
      //   toLogoOut();
      // }
      message.error(error.data?.status?.message || '未知异常');
      if (error.data?.status) {
        throw error.data;
      } else {
        return {
          status: {
            code: 'ERROR',
            message: massageText,
          },
        };
      }
    } else if (!response) {
      // 不能获取到请求响应的
      const type = error?.type || '';
      // 单独识别网络超时
      console.error(type === 'Timeout' ? '请求超时' : '网络异常');
      massageText = `网络异常`;
      message.error(massageText || '未知异常');
      // 不能获取到请求响应头 以自定义格式返回
      return {
        status: {
          code: 'ERROR',
          message: massageText,
        },
      };
    }
  },
  3000,
  { trailing: false },
);
/**
 * 配置request请求时的默认参数
 */
const request: ExRequestMethod = extend({
  errorHandler,
  // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
  timeout: 120000, // 超时
});

// 克隆响应对象做解析处理
request.interceptors.response.use(
  async (response, config: ExRequestOptionsInit) => {
    // 拦截器跳过blob下载
    if (config.responseType === 'blob') {
      // 尝试解析
      try {
        const data = await response.clone().json();
        return data;
      } catch (error) {
        return response;
      }
    }
    const data = await response.clone().json();
    const { status: codeObj } = data;
    const { code, message: msg } = codeObj;
    // 全局错误提示
    if (code !== '0000' && response.status === 200) {
      if (config.ignoreMsg) {
        console.log('不做提示:', msg);
      } else if (config.filterCode && Array.isArray(config.filterCode)) {
        let showMsg = true;
        // eslint-disable-next-line no-restricted-syntax
        for (const i of config.filterCode) {
          if (code.indexOf(i) !== -1) {
            showMsg = false;
            break;
          }
        }
        if (showMsg) {
          message.error(msg || '未知异常');
        }
      } else {
        message.error(msg || '未知异常');
      }
    }
    return response;
  },
);

// 请求前预处理
const RequestFinal = async (url: string, options: any = {}) => {
  const { filterParams, data, needAuth = false, needToken = true } = options;
  const token: string = getToken() || '';
  if (!token && needAuth) {
    console.error('前端拦截，请求取消,退出并返回到当前页面');
    // toLogoOut();
    return Promise.reject({
      code: {
        code: '0001',
        message: '用户没有令牌',
      },
    });
  }

  let headers = {};
  if (token && needToken) {
    headers = {
      Authorization: 'Bearer ' + token,
    };
  }

  // 如果接收到了过滤参数的配置
  if (filterParams) {
    // eslint-disable-next-line no-param-reassign
    options = {
      ...options,
      data: filterPostParams(data),
    };
  }
  try {
    const res = await request(url, { ...options, headers });

    // 可能会拿到blob数据，兼容
    if (!res?.status) {
      return Promise.resolve(res);
    }

    if (res?.status?.code === '0000') {
      return Promise.resolve(res);
    }

    // 未登录的时候判断 code 未登录退出 4202  4232
    if (['4202', '4232'].includes(res?.status?.code)) {
      console.log('退出');
      toLogoOut();
    }
    return Promise.reject(res);
  } catch (error) {
    console.log('🚀 ~ RequestFinal ~ error:', error);
    return Promise.reject(error);
  }
};

export default RequestFinal;
