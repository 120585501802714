/*
 * @Description: 用户相关
 * @Author: huangkun
 * @Date: 2020-05-07 11:13:45
 * @Last Modified by: huangkun
 * @Last Modified time: 2024-03-13 15:12:40
 */
import request from '@/utils/request';

import { getStringify } from '@/utils/request/utils';
import { deleteApi, getApi, postApi } from '../apiCommand';

// 查询账号列表分页
export async function userinfoPage(params: any) {
  return request(`${getApi.userinfoPage}${getStringify(params)}`);
}

// 查询账号列表
export async function userinfoList(params: any) {
  return request(`${getApi.userinfoList}${getStringify(params)}`);
}
// 查询账号列表
export async function userinfoCountBy(params: any) {
  return request(`${getApi.userinfoCountBy}${getStringify(params)}`);
}

// 查询账号id
export async function userinfoFindId(params: any) {
  return request(`${getApi.userinfoFindId}${params.userId}`);
}

// 查询应用信息
export async function userExtendInfo(params: any) {
  return request(`${getApi.userExtendInfo}${params.userId}`);
}

// 查询应用信息
export async function applicationInfo() {
  return request(`${getApi.applicationInfo}`, { needToken: false });
}

// 登录请求
export async function login(data: any) {
  return request(postApi.login, {
    method: 'post',
    data,
  });
}

// 签名
export async function wechatJsapiSign(data: any) {
  return request(postApi.wechatJsapiSign, {
    method: 'post',
    data,
    ignoreMsg: true,
  });
}

// 发送短信验证
export async function phoneCodeSend(data: any) {
  return request(postApi.phoneCodeSend, {
    method: 'post',
    data,
  });
}

// 重置用户密码
export async function userPwdReset(data: any) {
  return request(postApi.userPwdReset, {
    method: 'post',
    data,
  });
}

// 修改用户密码
export async function userPwdUpdate(data: any) {
  return request(postApi.userPwdUpdate + data.userId, {
    method: 'post',
    data,
  });
}

// 创建账号
export async function userinfoCreate(data: any) {
  return request(postApi.userinfoCreate, {
    method: 'post',
    data,
    filterParams: true,
  });
}

// 编辑账号
export async function userinfoUpdate(data: any) {
  return request(postApi.userinfoUpdate + data.userId, {
    method: 'post',
    data,
    filterParams: true,
  });
}

// 退出账号
export async function logout() {
  // 返回至路由
  return request(deleteApi.logOut, {
    method: 'delete',
  });
}

// 上传图片(文件)
export async function fileUpload(data: any) {
  return request(postApi.fileUpload, {
    method: 'post',
    data,
  });
}

// 绑定用户
export async function adminExtendCreate(data: any) {
  return request(postApi.adminExtendCreate, {
    method: 'post',
    data,
    filterParams: true,
  });
}

// 通过手机号（验证码）重置用户密码
export async function resetPwdByPhone(data: any) {
  return request(postApi.resetPwdByPhone, {
    method: 'post',
    data,
    filterParams: true,
  });
}

// 用户绑定手机号
export async function userPhoneBind(data: any) {
  return request(postApi.userPhoneBind, {
    method: 'post',
    data,
    filterParams: true,
  });
}
