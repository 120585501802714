// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/Users/jun/code/CQXM/MavenProjectSite/marketing-mall/marketing-mall-h5/src/models/filter';
import model_2 from '/Users/jun/code/CQXM/MavenProjectSite/marketing-mall/marketing-mall-h5/src/models/global';
import model_3 from '/Users/jun/code/CQXM/MavenProjectSite/marketing-mall/marketing-mall-h5/src/.umi-production/plugin-initialState/@@initialState';

export const models = {
model_1: { namespace: 'filter', model: model_1 },
model_2: { namespace: 'global', model: model_2 },
model_3: { namespace: '@@initialState', model: model_3 },
} as const
