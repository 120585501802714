import '@nutui/nutui-react/dist/style.css';
import { history, matchRoutes } from '@umijs/max';
import 'animate.css';
import Cookies from 'js-cookie';
import defaultSettings from '../config/defaultSettings';
import { applicationInfo, login } from './services/auth';
import { getToken, setToken, toLogoOut } from './utils/auth';
import { preTreatmentService } from './utils/request';

// 获取环境变量 NODE_ENV 的值
const env = process.env.NODE_ENV;

// 如果 NODE_ENV 的值为 development，则输出 '开发环境'
if (env === 'development') {
  console.log('开发环境');
  // new VConsole();
}

// 如果 NODE_ENV 的值为 production，则输出 '生产环境'
if (env === 'production') {
  console.log('生产环境');
  // new VConsole({ theme: 'dark' });
}

// 白名单
const whiteList = [
  '/scancode',
  '/preview/lottery_wheel',
  '/preview/lottery_answer',
  '/preview/lottery_square',
];

export async function getInitialState(): Promise<any> {
  // 获取应用信息
  const application = (await preTreatmentService(applicationInfo)()) || {};

  // 当前路径是否是白名单
  const isWhiteList = whiteList.includes(history.location.pathname);
  const displayName = application?.uiStyles?.displayName || '星途驿站';
  localStorage.setItem('displayName', displayName);
  // 当前的用户信息
  let currentUser = {};
  // 写入appid
  Cookies.set('appId', application?.appId, { expires: 1 });

  // 通过appid获取 token
  const token = getToken();

  // 白名单查询用户信息，需要额外判断
  if (token && !isWhiteList) {
    // 非白名单的需要去token刷新 ，白名单同时是扫码进入页面的token需要刷新
    try {
      const loginDate = await preTreatmentService(login)({
        type: 'sso_token',
        token: token,
      });
      currentUser = loginDate?.user;
      // 更新token
      setToken(loginDate?.token);
    } catch (error: any) {
      if (!['4202', '4232'].includes(error?.status?.code)) {
        toLogoOut();
      }
    }
  }

  return {
    settings: defaultSettings,
    currentUser: currentUser,
    applicationInfo: application,
  };
}

// // 根组件包括缓存组件
// export function rootContainer(
//   container:
//     | boolean
//     | React.ReactChild
//     | React.ReactFragment
//     | React.ReactPortal
//     | null
//     | undefined,
// ) {
//   return React.createElement(AliveScope, null, container);
// }
export async function onRouteChange({ clientRoutes, location }) {
  const route = matchRoutes(clientRoutes, location.pathname)?.pop()?.route;
  // 获取应用信息
  const displayName = localStorage.getItem('displayName') || '';
  if (route) {
    document.title = route?.title
      ? `${route?.title}${displayName ? `-${displayName}` : displayName}`
      : displayName;
  }
}
